$('document').ready(function () {
    const mobileMenu = $(`.mobile-menu`);

    $(`.hamburger-menu`).on(`click`, function(evt) {
        evt.preventDefault();
        if ($(this).hasClass(`hamburger-menu--open`)) {
            $(this).removeClass(`hamburger-menu--open`);
            $(this).parents(`.header-items`).removeClass(`header-items--open`)
        } else {
            $(this).addClass(`hamburger-menu--open`);
            $(this).parents(`.header-items`).addClass(`header-items--open`);
            $(this).parents(`.header-items`).find(`.search__input`).focus();
        }
    });

    let dropDownMenu = $(`.nav__text--submenu`).parent();

    $(`.nav__list`).on('click', `.nav__text--submenu`, function (evt) {
      evt.preventDefault();
      if ($(this).parent().hasClass(`nav__item--open`)) {
        dropDownMenu.removeClass(`nav__item--open`);
      } else {
        dropDownMenu.removeClass(`nav__item--open`);
        $(this).parent().addClass(`nav__item--open`);
      }
    });

  $(`.lang`).on('click', function (evt) {
    if ($(this).hasClass(`lang--open`)) {
      $(`.lang`).removeClass(`lang--open`);
    } else {
      $(this).addClass(`lang--open`);
    }
  })

});

$(document).on(`click`, function (evt) {

  if (!$(evt.target).closest(`.nav__text--submenu, .submenu`).length) {
    $(`.nav__text--submenu`).parent().removeClass(`nav__item--open`);
  }

  if (!$(evt.target).closest(`.mobile-menu__content`).length && !$(evt.target).closest(`.hamburger-menu`).length) {
    $(`.header-items`).removeClass(`header-items--open`);
    $(`.hamburger-menu`).removeClass(`hamburger-menu--open`);
  }

  if (!$(evt.target).closest(`.lang`).length) {
    $(`.lang`).removeClass(`lang--open`);
  }

});
